import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/egg.png";
import mcircle from "./assets/images/matic_circle.png";
import { contractAddress } from "./redux/blockchain/blockchainActions"

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #1cb84d;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const mintCost = useSelector((state) => state.data.cost / 10 ** 18);
  const [feedback, setFeedback] = useState(``);
  const [claimingNft, setClaimingNft] = useState(false);
  // Input field for name
  const [birbName, setMessage] = useState('');
  const handleChange = event => {setMessage(event.target.value);
    //console.log('value is:', event.target.value);
  }

  const estimateGas = async (acc, bname) => {
    blockchain.smartContract.methods.mint(acc, bname).estimateGas({from: blockchain.account})
    .then(function(gasAmount){
      console.log(gasAmount);
        return gasAmount;
    })
    .catch(function(error){
        return 500000;
    });
}

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    _amount = 1;
    let _birbName = "Birb";
    if(birbName != ""){
      _birbName = birbName;
    }

    setFeedback(`Minting a Birb called... ${_birbName}`);//("Minting your Birb...");
    setClaimingNft(true);
  
    blockchain.smartContract.methods
      .mint(blockchain.account, _birbName)
      .send({
        gasLimit: 5000000, //estimateGas(blockchain.account, _birbName),
        gasPrice: 50000000000,
        to: contractAddress,
        from: blockchain.account,
        value: data.cost//blockchain.web3.utils.toWei((100 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(`Mint declined/failed. Try again later`);
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Wohoo, you got a Birb! You can meet your new pet here https://birbz.pet"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData());
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "#468CE6" }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
        <s.TextTitle
          style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
        >
          Birbz Light Minter
        </s.TextTitle>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={i1} />
            <s.SpacerMedium />
            <s.TextTitle
              style={{ textAlign: "center", fontSize: 42, fontWeight: "bold" }}
            >
              {data.totalSupply}/2222
            </s.TextTitle>
          </s.Container>
          <s.SpacerMedium />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{ backgroundColor: "#5995e3", padding: 24 }}
          >
            {Number(data.totalSupply) == 2222 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  Mint has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  You can still find Birbz on{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/collection/birbz-world-first"}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  Adopt a cute Birb by minting one!
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center" }}>
                      Connect to the Polygon network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                        setFeedback(`Do you already know how your new pet will be called?`);
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription style={{ textAlign: "center" }}>
                      Name your Birb...
                    </s.TextDescription>
                    <s.InputName id="birbName" name="birbName" maxlength="15" onChange={handleChange} value={birbName} style={{ height: "40px" }}></s.InputName>
                    <s.SpacerSmall></s.SpacerSmall>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <StyledButton style={{width: "140px", height: "50px", fontSize: "16px" }}
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      MINT {data.cost/10**18} <StyledImg style={{ width: "18px", height: "18px", margin: "0px, 0px, 0px, 0px" }} src={mcircle}/>
                    </StyledButton>
                  </s.Container>
                  </s.Container>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <StyledButton style={{ width: "160px", height: "40px" }}><a href="https://birbz.pet">Back to BIRBZ.PET</a></StyledButton>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription style={{ textAlign: "center", fontSize: 16 }}>
            Gas price has been intentionally boosted so the transaction doesn't fail! You can adjust the price from Metamask. Although the price may seem high, any gas not used is returned to your wallet and actual gas price is lower than what is displayed
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
